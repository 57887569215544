<template>

  <div class="row" ref="templateForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">

<!--            //Type-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="sessions"
                  rules=""
                  name="The Sessions"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="type"
                    size="large"
                    filterable
                    clearable
                    multiple
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Sessions')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Sessions')"
                    :list="builderData.sessionList"
                    :listItemLabel="'title'"
                    :listItemValue="'id'"
                    v-model="formData.sessions">
                  </fg-select>
                </ValidationProvider>
              </div>
              <div class="col-md-12">
                <ValidationProvider
                  vid="cycles"
                  rules=""
                  name="The Cycles"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="type"
                    size="large"
                    filterable
                    clearable
                    multiple
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Cycles')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Cycles')"
                    :list="builderData.cycleList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.cycles">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Submit') }}
            </l-button>
            <l-button @click="$router.push('/referral-coupons/list')" type="danger" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Cancel') }}
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "@/components/Inputs/formGroupSelect";
import LSwitch from "../../../components/Switch";
import PaginatedSelect from "@/components/paginatedSelect";

extend("required", {
  message: "{_field_} is required"
});

export default {
  components:{
    LSwitch,
    FgSelect,
    PaginatedSelect
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      paginatedCompKey: 0,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        sessions: "",
        cycles: "",
      },
      builderData: {
        sessionList: [],
        cycleList: [],
      }
    };
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.templateForm
    });

    let data = {}
    this.axios.post("referral-coupons/builder", data).then((response) => {
      // this.builderData.memberList = response.data.members;
      this.builderData.sessionList = response.data.sessions;
      this.builderData.sessionList = response.data.sessions
        .map(obj => ({...obj, title: obj.training.title + ' ( ' + obj.days + ' )'}));
      this.builderData.cycleList = response.data.cycles;
      this.getBlackList();

      this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Referral Coupon Blacklist");
      this.loader.hide();

    }).catch((error) => {
      console.error(error);
    })


  },


  methods: {
    formatItem(item) {
      // Check if the item contains HTML tags using a regular expression
      const htmlRegex = /<[a-z][\s\S]*>/i;
      if (htmlRegex.test(item)) {
        // If item contains HTML, return it as is
        return item;
      } else {
        // Otherwise, escape special characters to prevent rendering as HTML
        const div = document.createElement('div');
        div.appendChild(document.createTextNode(item));
        return div.innerHTML;
      }
    },
    getBlackList() {
      this.axios.post("referral-coupons/get-blacklist").then((response) => {
        console.log(response.data.sessions)
        this.formData.sessions = response.data.sessions;
        this.formData.cycles = response.data.cycles;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: this.$store.getters['languageMixin/getStringFromLanguage']("Something went wrong"),
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      request = this.axios.post("referral-coupons/update-blacklist", this.formData);
      successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Referral Coupon Updated Successfully");
      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/referral-coupons/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      })
    },
  }
}
</script>

<style>
</style>
